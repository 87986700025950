import axios from 'axios';
import { BRAND_WOWCHER } from '../config/setup/setup';
import { findPageViewTrackUrl } from './analytics';

export const isFindPage = (url) => {
  if (process.env.NEXT_PUBLIC_BRAND !== BRAND_WOWCHER) return false;
  const findPageUrl = [
    '/coats-jackets',
    '/cosywear-nightwear',
    '/garden-lighting',
    '/home-decor',
    '/jewellery/anklets/gold-anklets',
    '/jewellery/anklets/silver-anklets',
    '/jewellery/bracelets/bangles',
    '/jewellery/bracelets/charm-bracelets',
    '/jewellery/bracelets/crystal-bracelets',
    '/jewellery/bracelets/friendship-bracelets',
    '/jewellery/bracelets/gold-bracelets',
    '/jewellery/bracelets/silver-bracelets',
    '/jewellery/bracelets/swarovski-bracelets',
    '/jewellery/bracelets/tennis-bracelets',
    '/jewellery/cufflinks/gold-cufflinks',
    '/jewellery/cufflinks/personalised-cufflinks',
    '/jewellery/cufflinks/silver-cufflinks',
    '/jewellery/earrings/diamond-crystal-earrings',
    '/jewellery/earrings/drop-earrings',
    '/jewellery/earrings/gold-earrings',
    '/jewellery/earrings/hoop-earrings',
    '/jewellery/earrings/pearl-earrings',
    '/jewellery/earrings/silver-earrings',
    '/jewellery/earrings/stud-earrings',
    '/jewellery/necklaces/chain-necklaces',
    '/jewellery/necklaces/gold-necklaces',
    '/jewellery/necklaces/name-necklaces-personalised',
    '/jewellery/necklaces/pearl-necklaces',
    '/jewellery/necklaces/pendant-necklaces',
    '/jewellery/necklaces/silver-necklaces',
    '/jewellery/personalised-jewellery/birthday-zodiac-jewellery',
    '/jewellery/personalised-jewellery/initial-name-jewellery',
    '/jewellery/rings/crystal-rings',
    '/jewellery/rings/gold-rings',
    '/jewellery/rings/mens-rings',
    '/jewellery/rings/silver-rings',
    '/jewellery/sets/bracelet-sets',
    '/jewellery/sets/crystal-sets',
    '/jewellery/sets/gold-jewellery-sets',
    '/jewellery/sets/pearl-sets',
    '/jewellery/sets/silver-jewellery-sets',
    '/kids/kids-baby-clothes/baby-grows-sleepsuits',
    '/kids/kids-baby-clothes/dresses',
    '/kids/kids-baby-clothes/hoodies-snuggle-blankets',
    '/kids/kids-baby-clothes/jackets-coats',
    '/kids/kids-baby-clothes/jumpers',
    '/kids/kids-baby-clothes/personalised-clothes',
    '/kids/kids-baby-clothes/pyjamas-robes-dressing-gowns',
    '/kids/kids-baby-clothes/sleepsuits-onesies',
    '/kids/kids-baby-clothes/swimming',
    '/kids/kids-baby-clothes/tops',
    '/kids/kids-baby-clothes/underwear',
    '/kids/nursery/baby-bath-essentials',
    '/kids/nursery/baby-proofing-health-safety',
    '/kids/nursery/car-seats',
    '/kids/nursery/changing-bags-mats',
    '/kids/nursery/cots-cot-beds',
    '/kids/nursery/cribs-moses-baskets',
    '/kids/nursery/highchairs-weaning',
    '/kids/nursery/nursing-feeding',
    '/kids/nursery/potty-training',
    '/kids/nursery/prams-push-chairs-strollers',
    '/kids/school/books',
    '/kids/school/lunch-boxes',
    '/kids/school/school-bags',
    '/kids/school/school-uniform',
    '/kids/school/stationery',
    '/kids/toys',
    '/beauty/spa',
  ];

  return (
    !url.includes('deals/beauty') &&
    !url.includes('near-me') &&
    findPageUrl.some((subUrl) => url.includes(subUrl))
  );
};

export const getShopUrl = (url) => {
  const urlParts = url.split('?')[0].split('/');
  urlParts[2] = 'shop';

  return urlParts.join('/');
};

export const isFindPath = (string) => {
  let count = 0;
  for (const element of string) {
    if (element === '/') {
      count++;
    }
  }

  return (
    (string.includes('json') && count > 6) ||
    (!string.includes('json') && count > 3)
  );
};

export const isFindListItem = (string) => {
  let count = 0;
  for (const element of string) {
    if (element === '/') {
      count++;
    }
  }

  return count > 4;
};

export const showArrows = ({ recommendation, isMobile, navigations }) => {
  return (
    (!recommendation && !isMobile && navigations.length > 4) ||
    (isMobile && navigations.length > 2)
  );
};

export const pageView = async ({ category, subcategory }) => {
  const url = findPageViewTrackUrl({ category, subcategory });
  if (!url) return null;
  try {
    return await axios({
      cache: false,
      method: 'GET',
      url,
      validateStatus: (status) => {
        return status < 500;
      },
    });
  } catch (error) {
    console.error(error);

    return null;
  }
};
export const locationList = [
  'aberdeen',
  'bath',
  'belfast',
  'birmingham',
  'blackpool',
  'bournemouth',
  'bradford',
  'brighton',
  'bristol',
  'cambridge',
  'cardiff',
  'cork',
  'cornwall',
  'coventry',
  'derby',
  'dublin',
  'edinburgh',
  'essex',
  'exeter',
  'galway',
  'glasgow',
  'gloucestershire',
  'grimsby',
  'hull-and-east-riding',
  'ipswich',
  'kent',
  'leeds',
  'leicester',
  'limerick',
  'lincolnshire',
  'liverpool',
  'london',
  'london-east',
  'london-north',
  'london-south',
  'london-west',
  'manchester',
  'milton-keynes',
  'national-deal',
  'national-deal',
  'newcastle',
  'northampton',
  'norwich',
  'nottingham',
  'oxford',
  'peterborough',
  'plymouth',
  'portsmouth',
  'preston',
  'reading',
  'sheffield',
  'southampton',
  'southeastireland',
  'stoke-on-trent',
  'surrey',
  'swansea',
  'travel',
  'travel',
  'wolverhampton',
  'york',
];
