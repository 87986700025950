/* eslint-disable react/forbid-component-props, @next/next/no-img-element */
/* WowModal - a wrapper for react bootstrap modal.
  The parent of this component will manage its state.
 */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { VIP_LOGO } from '../config/constants/images';
import { MODALS } from '../config/constants/lightbox';
import { VIP_PAYMENT_MODULE_TEXT } from '../config/text/text';
import ThemeContext from '../providers/ThemeProvider';

const WowModal = ({
  ariaLabelledBy,
  backdrop,
  backdropClassName,
  centered,
  children,
  container,
  dialogId,
  dialogClassName,
  enforceFocus = true,
  extraClasses,
  titleClasses,
  keyboard = true, // esc will work by default
  isPostCheckout = false,
  setShowModal,
  showCloseButton = true,
  showHeader = true,
  size,
  title,
  titleImage = null,
  titleImageAlt = null,
  topPosition,
  showModal,
  vipPayment,
}) => {
  const theme = useContext(ThemeContext);

  if (!showModal) return null;

  const setModalId = (dialogElement) => {
    dialogElement.setAttribute('id', dialogId);
  };
  const titleElement = title && (
    <Modal.Title
      className={
        titleClasses ? titleClasses : 'example-custom-modal-styling-title'
      }
    >
      {vipPayment ? (
        <div className="vip-payment-header">
          <img
            alt="Logo"
            className="paywall-payment-vip-image"
            src={VIP_LOGO[process.env.NEXT_PUBLIC_BRAND]}
            style={{ maxHeight: '100%', maxWidth: '50%' }}
          />
          <p className="vip-payment-header-text">{VIP_PAYMENT_MODULE_TEXT}</p>
        </div>
      ) : (
        title
      )}
    </Modal.Title>
  );
  const titleImageElement = titleImage && (
    <Modal.Title className="modal-title-image">
      <img alt={titleImageAlt} src={titleImage} width="81px" />
    </Modal.Title>
  );

  return (
    <>
      <Modal
        aria-labelledby={ariaLabelledBy}
        backdrop={backdrop}
        backdropClassName={backdropClassName}
        centered={centered}
        className={extraClasses}
        container={container}
        dialogClassName={dialogClassName}
        enforceFocus={enforceFocus}
        keyboard={keyboard}
        onEntered={setModalId}
        onHide={() => setShowModal(false)}
        show={showModal}
        size={size}
      >
        {showHeader && (
          <Modal.Header
            closeButton={vipPayment ? false : showCloseButton}
            style={
              vipPayment
                ? { backgroundColor: 'black' }
                : isPostCheckout
                ? { height: 0, padding: 0 }
                : {}
            }
          >
            {titleImageElement ? titleImageElement : titleElement}
          </Modal.Header>
        )}
        <Modal.Body style={vipPayment ? { backgroundColor: 'black' } : {}}>
          {children}
        </Modal.Body>
      </Modal>
      <style global jsx>{`
        @media (max-width: ${theme.breakpoints.mdDown}) {
          .header-icon--hidden-mobile.modal {
            display: none !important;
          }
        }
        .vip-payment-header {
          display: flex;
          background-color: black;
          color: white;
          margin: 10px;
        }
        .vip-payment-header-text {
          margin: 10px;
          font-size: 18px;
        }
        @media (max-width: ${theme.breakpoints.smDown}) {
          .vip-payment-header-text {
            margin: 5px;
            font-size: 16px;
          }
          .paywall-payment-vip-image {
            height: 80px;
          }
        }

        // defaults
        .modal-open {
          padding-left: 0 !important;
        }
        .modal-open .modal {
          padding-left: 0 !important;
        }
        .vip-modal {
          position: fixed;
          top: 10%;
        }

        .voucher-redeem-modal {
          border-radius: 10px;
          margin-top: 50px;
          height: calc(100vh - (${topPosition + 52}px));
          z-index: 3000 !important;
        }
        .modal-content {
          border: none;
          border-radius: 6px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
        }

        .modal-header .close {
          font-size: 18px;
          padding-right: 20px;
          padding-top: 20px;
          z-index: 1000;
        }

        .modal-body .deal-main-deal__discount {
          background-color: ${theme.colors.primary};
        }
        .popup-bg-white {
          background-color: ${theme.commoncolors.greylighter};
        }

        // resset animation
        .modal.show .modal-dialog {
          animation: none;
          transition: none;
          transform: none;
        }
        .modal.fade .modal-dialog {
          animation: none;
          transition: none;
          transform: none;
        }
        .modal.modal-static .modal-dialog {
          animation: none;
          transition: none;
          transform: none;
        }

        // Checkout Timer Popup
        .checkout-timer-popup {
          max-width: 350px;
        }

        // genetic styles
        .no-body-padding .modal-body {
          padding: 0;
        }
        .no-rounded-corners .modal-content {
          border-radius: 0;
        }
        .remove-backdrop {
          opacity: 0;
        }
        .remove-header .modal-header {
          display: none;
        }
        .remove-backdrop.show {
          opacity: 0;
        }
        .move-backward.modal {
          z-index: 1040;
        }
        @media (max-width: ${theme.breakpoints.mdUp}) {
          .fullscreen-mobile .modal-dialog {
            width: 100%;
            min-width: 100%;
            min-height: 100%;
            height: 100%;
            top: 0;
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            margin: 0;
          }
          .checkout-timer-popup {
            max-width: 100%;
          }
        }

        // animation move
        @keyframes mymove {
          from {
            transform: translateY(100px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
        .with-animation .modal-content {
          animation: mymove 0.1s ease-out forwards;
        }

        // animation fade
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        .with-fade-in .modal-content {
          animation: fade-in 0.1s;
        }

        // animation slide in
        @keyframes slide-in {
          0% {
            transform: translateX(-100vw);
          }
          100% {
            transform: translateX(0vw);
          }
        }
        @media (prefers-reduced-motion: reduce) {
          @keyframes slide-in {
            0% {
              transform: none;
            }
            100% {
              transform: none;
            }
          }
        }

        @keyframes slide-out {
          100% {
            transform: translateX(-100vw);
          }
          0% {
            transform: translateX(0vw);
          }
        }
        @media (prefers-reduced-motion: reduce) {
          @keyframes slide-out {
            100% {
              transform: none;
            }
            0% {
              transform: none;
            }
          }
        }

        .with-slide-in.fade .modal-dialog {
          animation: slide-out 0.1s;
        }
        .with-slide-in.show .modal-dialog {
          animation: slide-in 0.1s;
        }

        // burger menu
        @media (max-width: ${theme.breakpoints.mdUp}) {
          .slide-out-menu .modal-dialog {
            width: calc(100vw - (100px));
            height: calc(100vh - (${topPosition + 52}px));
            bottom: 0;
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            margin: 0;
          }
          .is-logged-in.slide-out-menu .modal-dialog {
            // height: calc(100vh - (97px));
          }
          .slide-out-menu .modal-content {
            height: 100%;
            width: 100%;
          }
          .slide-out-menu .modal-body {
            background-color: ${theme.colors.navbackground};
          }
        }

        // headerlogin-menu
        .headerlogin-menu {
          position: absolute;
          top: 10px;
          max-width: 180px;
          height: fit-content;
          -webkit-transform: translateX(5%);
          -ms-transform: translateX(5%);
          transform: translateX(5%);
        }
        .headerlogin-menu .modal-content {
          border: none;
          max-width: 130px;
          text-align: left;
          z-index: 500;

          -webkit-transform: translateX(30%);
          -ms-transform: translateX(30%);
          transform: translateX(30%);
        }
        .headerlogin-menu .modal-body {
          padding: 12px;
        }

        // subscribe confirmation
        .with-header-background button span {
          font-size: 14px;
          color: white;
          top: 0;
          position: relative;
          display: flex;
          font-weight: 100;
        }
        .with-header-background .modal-header {
          background: ${theme.colors.primary};
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          color: #fff;
        }
        .with-header-background .modal-header .modal-title {
          font-weight: 200;
        }
        .with-header-background .modal-content {
          width: 100%;
          height: 100%;
        }
        .with-header-background .modal-body {
          padding: 0;
        }

        // subscribepopup
        .subscribepopup .modal-content {
          left: 80%;
          margin-top: 40px;
          max-width: 400px;
          transform: translateX(-75%);
          z-index: 500;
        }
        @media (min-width: ${theme.breakpoints.lgUp}) {
          .subscribepopup .modal-content {
            transform: translateX(-50%);
          }
        }
        @media (min-width: ${theme.breakpoints.xlUp}) {
          .subscribepopup .modal-content {
            transform: translateX(-25%);
          }
        }
        .modal-title-image {
          margin-left: 200px;
          padding: 10px 0 20px 0;
        }
        @media (max-width: ${theme.breakpoints.smDown}) {
          .modal-title-image {
            margin-left: 130px;
            padding: 10px 0 20px 0;
          }
        }

        // booking calendar on redemption message
        .bcor-message {
          left: 10px;
          position: absolute;
          width: 95%;
        }
        .bcor-message .modal-body {
          padding-top: 0;
        }
      `}</style>
    </>
  );
};

export default WowModal;

WowModal.propTypes = {
  ariaLabelledBy: PropTypes.string.isRequired,
  backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  backdropClassName: PropTypes.string,
  centered: PropTypes.bool,
  children: PropTypes.node,
  container: PropTypes.object, // a DOM node
  dialogClassName: PropTypes.string,
  dialogId: PropTypes.string,
  extraClasses: PropTypes.string,
  isPostCheckout: PropTypes.bool,
  keyboard: PropTypes.bool,
  setShowModal: PropTypes.func,
  showCloseButton: PropTypes.bool,
  showHeader: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  size: PropTypes.string,
  title: PropTypes.string,
  titleClasses: PropTypes.string,
  titleImage: PropTypes.string,
  titleImageAlt: PropTypes.string,
  topPosition: PropTypes.number,
  vipPayment: PropTypes.bool,
};

WowModal.defaultProps = {
  backdrop: true,
  backdropClassName: '',
  centered: false,
  dialogId: MODALS.default,
  extraClasses: '',
  setShowModal: () => {},
  size: '',
  title: '',
  titleClasses: '',
  vipPayment: false,
};
